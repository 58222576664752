<template>
	<div class="content-wrap">
		<Spin fix v-if="progressListLoading"></Spin>
		<div class="project-process-title">
			<h2>流程管理</h2>
		</div>
		<div class="information">
			流程状态：
			<span class="label">已完成：<em class="state1"></em></span>
			<span class="label">进行中：<em class="state2"></em></span>
			<Button
				type="primary"
				class="ivu-btn-right"
				v-if="isShowTermBtn"
				style="margin-right: 8px"
				@click.stop="handleTerminate()"
			>
				项目终止
			</Button>
			<Button
				type="primary"
				class="ivu-btn-right"
				:class="[{ 'complete-btn': !followCheckData.notifyType }]"
				style="margin-right: 8px"
				v-if="activeName === 'OTHER'"
				@click.stop="handleFollowCheck()"
			>
				跟踪审查
			</Button>
			<!--			<Button-->
			<!--				class="add-flow-btn ivu-btn-right"-->
			<!--				icon="md-add"-->
			<!--				@click="processSetting"-->
			<!--				style="margin-right: 8px"-->
			<!--				>新增流程</Button-->
			<!--			>-->
		</div>
		<div class="project-process-body" style="height: calc(100% - 70px)">
			<template>
				<Tabs :animated="false" v-model="activeName" @on-click="handeClickTabs">
					<TabPane
						:label="item.label"
						v-for="(item, index) in progressListDataA"
						:key="index"
						:name="item.type"
					>
						<CtmsDataGridTree
							:showIndex="false"
							:height="gridHeight"
							:columns="columns"
							:data="listData"
							:page="page"
              :indent-size="0"
							row-key="name"
						></CtmsDataGridTree>

					</TabPane>
				</Tabs>
			</template>
      <Approval
        :visible="approvalVisible"
        :projectId="projectId"
        :taskId="taskId"
        @onCancel="approvalVisible = false"
        @onOk="handleOk"
        :type="approvalType"
        :toDoId="toDoId"
        :procInstId="procInstId"
        :procInstName="procInstName"
      ></Approval>
      <StartConfirm
        :visible="startVisible"
        :list-data="listData"
        @onOk="handleStartProcess"
        @onCancel="startVisible = false"
      >
      </StartConfirm>
		</div>
		<!--		<List-->
		<!--			:visible="listVisible"-->
		<!--			:category="category"-->
		<!--			@startOk="handleOk"-->
		<!--			@onCancel="listVisible = false"-->
		<!--			:modelId="modelId"-->
		<!--		></List>-->

		<ProcessSetting
			:visible="processVisible"
			@onCancle="processVisible = false"
			@onOk="processSettingOk"
			:updateId="updateId"
			:projectConfigId="projectConfigId"
			:tabName="activeName"
		>
		</ProcessSetting>

		<!--项目终止-->
		<terminate-project
			:visible="terminateVisible"
			@onOk="handleTerminateOnOk"
			@onCancel="terminateVisible = false"
		></terminate-project>
		<!--跟踪审查-->
		<follow-check
			:visible="followCheckVisible"
			@onOk="handleFollowCheckOk"
			@onCancel="followCheckVisible = false"
		></follow-check>
	</div>
</template>

<script>
import { mapState } from "vuex"

import api from "@/api/project/process"
import processApi from "@/api/setting/process"
import Approval from "@approval"
// import List from './List.vue';
import StartConfirm from "./StartConfirm.vue"
import ProcessSetting from "./processSetting.vue"
import TerminateProject from "./TerminateProject.vue"
import FollowCheck from "./FollowCheck.vue"

const {
	apiStartProcess,
	apiModelRedeploy,
	apiDelProcess,
	apiGetModelList
} = api
const { apiGet } = processApi

export default {
	name: "index",
	components: {
		// List,
		Approval,
		StartConfirm,
		ProcessSetting,
		TerminateProject,
		FollowCheck
	},
	data() {
		return {
			modelId: "",
			category: "",
			// listVisible: false,

			activeName: "START",
			approvalType: "view",
			taskId: "",
			toDoId: "",
			loading: false,
			fullscreen: false,
			listData: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			columns: [
				{
					title: "流程名称",
					key: "name",
					minWidth: 150,
					tree: true
				},
				{
					title: "流程状态",
					key: "status",
					width: 100
				},
				{
					title: "最新启动时间",
					key: "startTime"
				},
				{
					title: "启动人",
					key: "startUserName"
				},
				{
					title: "启动次数",
					key: "startNumber",
					render: (h, params) => h("span", {}, this.setStatusTip(h, params))
				},
				{
					title: "最新完成时间",
					key: "startTime"
				},
				{
					title: "操作",
					key: "action",
					minWidth: 170,
					renderButton: params => {
						const actionList = [
							{
								// 0
								label: params.row.todoAuth ? "进入流程" : "查看",
								on: {
									click: this.handleApporovalViewShow
								}
							},
							{
								// 1
								label: "导出批注",
								on: {
									click: this.handleExportRemark
								}
							},
							{
								// 2
								label:
									this.env === "shetyy" && this.category === "START"
										? "导出"
										: "导出审批意见",
								on: {
									click: this.export
								},
								confirm: {
									title: `您确认导出该流程内的${this.env === "shetyy" && this.category === "START" ? "立项清单" : "所有审批意见"}吗？`
								}
							},
							{
								// 3
								label: "重新部署",
								on: {
									click: this.handleRedeploy
								}
							},
							{
								// 4
								label: "删除",
								on: {
									click: this.handleDelete
								},
								confirm: {
									title: "是否删除此流程？"
								}
							},
              {
                // 5
                label: params.row.startNumber > 0 ? "再次启动流程" : "启动流程",
                style: {
                  color: params.row.startNumber > 0 ? "#ff9900" : "#2D8cF0",
                },
                on: {
                  click: this.handleStartConfirm
                }
              },
						]
            if(!params.row.isChild){
              if (!params.row.sysModelId) {
                actionList[5] = null
              } else {
                actionList[0] = null
                actionList[1] = null
                actionList[2] = null
                actionList[3] = null
                actionList[4] = null
              }
              // 用户id为 1 或者 2 ,代表是admin和super 管理员身份
              if (
                this.$store.state.user.info.id !== "1" &&
                this.$store.state.user.info.id !== "2" &&
                !params.row.sysModelId
              ) {
                actionList[3] = null
                actionList[4] = null
              }
              if (
                this.env !== "shenzhenrenmin" ||
                this.projectActionPermissions.indexOf(
                  "btn_project_export_annotation"
                ) === -1
              ) {
                actionList[1] = null
              }
              if (
                this.env === "shetyy" &&
                this.category === "START" &&
                params.row.status === "进行中"
              ) {
                actionList[2] = null
              }
            }
						return actionList.filter(item => !!item)
					}
				}
			],
			approvalVisible: false,
			startVisible: false,
			procInstId: "",
			procInstName: "",

			processVisible: false, // 流程设置
			updateId: "",
			projectConfigId: "",

			followCheckVisible: false,
			terminateVisible: false,
      progressListDataA: [
        {
          type: 'START',
          label: '项目立项阶段',
        },
        {
          type: 'ING',
          label: '项目启动阶段',
        },
        {
          type: 'OTHER',
          label: '项目在研阶段',
        },
        {
          type: 'END',
          label: '项目结题阶段',
        },
      ],
		}
	},
	created() {
		// this.initTabs()
		this.initList()
	},
	computed: {
		...mapState("project", ["projectInfo", "followCheckData"]),
		...mapState("user", ["processList"]),
		...mapState("process", ["progressListData", "progressListLoading"]),
		...mapState("permission", ["projectActionPermissions"]),
		isXinhua() {
			// 环境
			return this.$store.state.environment === "xinhua"
		},
		...mapState({
			dataGridHeight: state => state.contentHeight - 200
		}),
		gridHeight() {
			return this.fullscreen
				? document.body.clientHeight - 150
				: this.dataGridHeight
		},
		env() {
			return this.$store.state.environment
		},
		projectId() {
			return this.$route.params.projectId
		},
		isShowTermBtn() {
			return (
				this.projectActionPermissions.indexOf("btn_project_termination") > -1 &&
				this.projectInfo.status !== 0 &&
				this.projectInfo.status !== 3 &&
				!this.projectInfo.terminateDate
			)
		}
	},
	methods: {
		// 设置状态标签
		setStatusTip(h, params) {
			if (!params.row.sysModelId) {
				if (params.row.endTime) {
					return [
						h(
							"em",
							{
								style: {
									display: params.row.endNumber ? "inline-block" : "none",
									width: "20px",
									height: "20px",
									textAlign: "center",
									fontStyle: "normal",
									color: "#fff",
									borderRadius: "10px",
									background: "#00ff00"
								}
							},
							params.row.endNumber
						)
					]
				} else {
					return [
						h(
							"em",
							{
								style: {
									display: params.row.startNumber ? "inline-block" : "none",
									width: "20px",
									height: "20px",
									textAlign: "center",
									fontStyle: "normal",
									color: "#fff",
									borderRadius: "10px",
									background: "#0a84ff"
								}
							},
							params.row.startNumber
						)
					]
				}
			} else {
				return [
					h(
						"em",
						{
							style: {
								display: params.row.endNumber ? "inline-block" : "none",
								width: "20px",
								height: "20px",
								textAlign: "center",
								fontStyle: "normal",
								color: "#fff",
								borderRadius: "10px",
								background: "#00ff00",
								marginRight: "8px"
							}
						},
						params.row.endNumber
					),
					h(
						"em",
						{
							style: {
								display: params.row.startNumber ? "inline-block" : "none",
								width: "20px",
								height: "20px",
								textAlign: "center",
								fontStyle: "normal",
								color: "#fff",
								borderRadius: "10px",
								background: "#0a84ff"
							}
						},
						params.row.startNumber
					)
				]
			}
		},
		// 流程设置
		processSetting() {
			this.processVisible = true
			this.updateId = this.projectId
			this.projectConfigId = this.projectInfo.projectConfigId
		},

		// 流程设置成功
		processSettingOk() {
			this.processVisible = false
			this.updateId = ""
			this.projectConfigId = ""
		},
		// 跟踪审查
		handleFollowCheck() {
			this.followCheckVisible = true
		},
		// 跟踪审查提交
		handleFollowCheckOk() {
			// 更新跟踪审查信息
			this.$store.dispatch("project/getFollowCheck", this.projectId) // 获取跟踪审查信息
			this.followCheckVisible = false
		},

		// 项目终止
		handleTerminate() {
			this.terminateVisible = true
		},
		// 项目终止成功
		handleTerminateOnOk() {
			// 更新项目信息（提交流程，项目状态改变）
			this.$store.dispatch("project/getProjectInfo", this.projectId)
			this.terminateVisible = false
		},
		handeClickTabs(name) {
			this.initList(name)
		},
		// 启动成功
		handleOk() {
			this.listVisible = false
			this.initList() // 更新流程列表
			this.loading = true
			// 更新待审批流程数量
			this.$store.dispatch(
				"process/getProjectToDoNum",
				this.$route.params.projectId
			)
			// 获取进行中流程的数据（判断各类流程的状态）
			this.$store.dispatch(
				"project/getProjectProcessNum",
				this.$route.params.projectId
			)
			// 更新项目信息（提交流程，项目状态改变）
			this.$store.dispatch(
				"project/getProjectInfo",
				this.$route.params.projectId
			)
			// 更新项目列表
			this.$store.dispatch("project/getProjectList")
			this.loading = false
		},
		//  根据流程颜色显示
		getColorClass(type) {
			let color = "pink"
			switch (type) {
				case 0:
					color = "pink"
					break
				case 1:
					color = "blue"
					break
				case 2:
					color = "green"
					break
				default:
					color = "pink"
					break
			}
			return color
		},
		// 更新tabs
		async initTabs() {
			this.loading = true
			await this.$store.dispatch("user/getProcessList").then(() => {
				this.$store
					.dispatch("process/getProgressListData", {
						projectId: this.$route.params.projectId,
						processList: this.processList
					})
					.then(() => {
						this.loading = false
					})
			})
		},
		// 查看已启动列表
		// handleProcessListShow(row) {
		//   this.listVisible = true;
		//   this.modelId = row.sysModelId;
		//   this.category = row.category;
		// },
		// 初始化列表
		initList(category) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetModelList({
					projectId: this.$route.params.projectId,
					category: category || this.activeName || "START"
				})
				if (res) {
					this.listData = res.data.map(item => {
						item["startNumber"] = 0
						item["endNumber"] = 0
						item["_showChildren"] = true

            if(item.children.length === 1){
              Object.assign(item,item.children[0],{
                status: item.children[0].endTime ? "已完成" : "进行中",
                startNumber: item.children[0].endTime ? 0 : 1,
                endNumber: item.children[0].endTime ? 1 : 0
              })
              item["isChild"] = true
              item.children = []
            }else{
              item["isChild"] = false
              item.children.forEach(child => {
                Object.assign(child, {
                  status: child.endTime ? "已完成" : "进行中",
                  startNumber: child.endTime ? 0 : 1,
                  endNumber: child.endTime ? 1 : 0
                })
                if (child.endTime) {
                  item["endNumber"]++
                } else {
                  item["startNumber"]++
                }
              })
            }


						return item
					})
					// this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 查看流程 / 流程审批
		handleApporovalViewShow({ row }) {
			if (row.todoAuth) {
				this.approvalType = "toDo"
				this.toDoId = row.latestTodoId
			} else {
				this.approvalType = "view"
				this.toDoId = ""
			}
			this.procInstId = row.id
			this.procInstName = row.name
			this.taskId = row.taskId
			this.$nextTick(() => {
				this.approvalVisible = true
			})
		},
		// 审批提交成功
		handleOk_new() {
			this.approvalVisible = false
			this.$store.dispatch(
				"project/getFollowCheck",
				this.$route.params.projectId
			) // 获取跟踪审查信息
			this.$store.dispatch("project/getExistCode", this.projectId) // 获取编号修改信息
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 导出批注
		handleExportRemark({ row }) {
			const url = `${this.$baseUrl}/flowable/process/process-annotation-export/${this.projectId}?procInstId=${row.id}&token=${this.$store.state.user.token}`
			window.open(url)
		},
		// 导出该流程内的所有审批意见
		// 儿童医院，导出内容由三部分组成：项目信息、此流程流程审批记录、此流程已上传文件名称
		export({ row }) {
			let url = ""
			// 儿童医院环境，【流程管理】，只有立项阶段下的流程才展示导出按钮,
			// 导出内容包含三部分：项目信息，此流程审批记录，此流程已上传文件名称
			if (this.env === "shetyy" && this.category === "START") {
				url = `${this.$baseUrl}/flowable/process/process-data-export/${this.projectId}?procInstId=${row.id}&token=${this.$store.state.user.token}`
			} else {
				url = `${this.$baseUrl}/flowable/process/${row.id}/audit-opinion-export?token=${this.$store.state.user.token}`
			}
			window.open(url)
		},
		// 删除流程
		async handleDelete({ row }) {
			this.loading = true
			const res = await apiDelProcess({
				projectId: this.projectId,
				procInstId: row.id,
				reason: "删除流程"
			})
			if (res) {
				this.$Message.success("操作成功!")
				this.page.current = 1
				this.$nextTick(() => {
					this.initList()
				})
			}
			this.loading = false
		},
		// 重新部署流程
		handleRedeploy({ row }) {
			this.$Modal.confirm({
				title: "重新部署流程",
				content:
					"您确认重新部署此流程吗？（提示：请先到流程设计页面修改此流程，再进行部署。）",
				onOk: async () => {
					const res = await apiModelRedeploy({
						processDefinitionId: row.processDefinitionId,
						processDeploymentId: row.processDeploymentId,
						modelId: row.businessKey,
						procInstId: row.id
					})
					if (res) {
						this.$Message.success("流程重新部署成功")
					}
				}
			})
		},
		// 跳转到流程设计页面
		handleToDesign() {
			sessionStorage.setItem("searchParams", "")
			this.$router.push({
				name: "settingProcess",
				query: {
					projectId: this.projectId
				}
			})
		},
		// 关闭
		onCancel() {
			// this.$emit('onCancel');
			this.$emit("startOk")
			this.fullscreen = false
		},
		// 启动流程确认
		handleStartConfirm({ row }) {
			this.modelId = row.sysModelId
			this.startVisible = true
		},
		// 启动流程
		handleStartProcess(sendSysMessage) {
			console.log(this.modelId)
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiStartProcess({
					projectId: this.projectId,
					modelId: this.modelId,
					sendSysMessage
				})
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
					// this.$emit('startOk');
				} else {
					this.loading = false
				}
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		}
	}
}
</script>

<style lang="less" scoped>
.project-process-title {
	height: 35px;
	h2 {
		font-size: 18px;
		color: #202730;
		line-height: 35px;
	}
}
.information {
	position: relative;
	height: 32px;
	line-height: 32px;
	.label {
		margin-right: 10px;
		em {
			display: inline-block;
			width: 10px;
			height: 10px;
			border-radius: 5px;
		}
		.state1 {
			background: #00ff00;
		}
		.state2 {
			background: #0a84ff;
		}
		.state3 {
			background: #5e6470;
		}
	}

	.add-flow-btn {
		margin-left: 8px;
		padding: 3px 20px 4px;
		border-color: #2d8cf0;
		color: #2d8cf0;
		font-size: 14px;
	}
	.complete-btn {
		margin-left: 8px;
		background: #fff;
		color: #333;
		border-color: #333;
	}
}
.project-process-body {
	height: 100%;
	.__title {
		height: 50px;
		line-height: 50px;
		font-size: 20px;
		padding-left: 16px;
	}
}
</style>

<style lang="less">
.ivu-table .processing-row td {
	background-color: #dcf5ff;
	color: #3a97f6;
	a {
		color: #3a97f6;
	}
}
.project-process-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
}
</style>
