<template>
	<!--项目终止-->
	<Modal
		class="terminate-project-modal"
		:value="visible"
		width="800"
		title="项目终止"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			v-if="visible"
			:model="formData"
			ref="terminateForm"
			:rules="rules"
			:label-width="150"
			class="terminate-form"
		>
			<FormItem label="项目终止日期：" prop="terminateDate">
				<DatePicker
					type="date"
					v-model="formData.terminateDate"
					@on-change="v => (formData.terminateDate = v)"
					format="yyyy-MM-dd"
					placeholder="请选择项目终止日期"
				>
				</DatePicker>
			</FormItem>
			<FormItem label="项目终止理由：" prop="terminateRemark">
				<Input
					class="reason-input"
					type="textarea"
					:maxlength="300"
					v-model.trim="formData.terminateRemark"
					placeholder="请输入项目终止理由，300字以内"
				/>
			</FormItem>
			<FormItem label="上传终止报告：">
				<Upload
					action="/"
					multiple
					:default-file-list="uploadFileList"
					:show-upload-list="true"
					:before-upload="beforeUpload"
					:on-remove="removeFile"
				>
					<Button :loading="loading" type="warning">上传文件</Button>
				</Upload>
			</FormItem>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/project"
import { mapState } from "vuex"

const { apiTerminateProject } = api

export default {
	name: "terminateProject",
	props: ["visible"],
	data() {
		return {
			loading: false,
			formData: {
				terminateDate: "",
				terminateRemark: ""
			},
			uploadFileList: [],
			rules: {
				terminateDate: {
					required: true,
					message: "项目终止日期不能为空",
					trigger: "change blur"
				},
				terminateRemark: {
					required: true,
					message: "项目终止理由不能为空",
					trigger: "blur"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.formData = {
					terminateDate: "",
					terminateRemark: ""
				}
				this.uploadFileList = []
				this.$refs.terminateForm && this.$refs.terminateForm.resetFields()
			}
		}
	},
	computed: {},
	created() {},
	methods: {
		beforeUpload(file) {
			this.uploadFileList.push(file)
			return false
		},
		removeFile(file, fileList) {
			this.uploadFileList = fileList
		},
		// 提交
		onOk() {
			this.$refs.terminateForm.validate(valid => {
				if (valid) {
					this.$Modal.confirm({
						title: "项目终止",
						content: "您确认要终止该项目吗？",
						onOk: async () => {
							const fd = new FormData()
							Object.keys(this.formData).forEach(key => {
								fd.append(key, this.formData[key])
							})
							this.uploadFileList.forEach(file => {
								fd.append("files", file)
							})
							this.loading = true
							const res = await apiTerminateProject(
								this.$route.params.projectId,
								fd
							)
							if (res) {
								this.$Message.success("项目终止成功！")
								this.$emit("onOk")
							}
							this.loading = false
						}
					})
				}
			})
		},
		// 关闭
		onCancel() {
			this.$emit("onCancel")
		}
	}
}
</script>

<style lang="less">
.terminate-project-modal {
	.reason-input {
		width: 500px;
		textarea.ivu-input {
			font-size: 14px;
		}
	}
}
</style>
