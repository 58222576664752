import request from "@/utils/request"

export default {
	// 获取项目下选中流程
	getProjFlowble: data =>
		request(`/flowable/project/${data.projectId}/models`, { method: "get" }),
	// 获取项目类型下所有流程
	getAllFlowble: data =>
		request("/flowable/models-project-config", { method: "get", params: data }),
	// 设置流程
	saveProjFlowble: data =>
		request(`/flowable/project/${data.projectId}/models`, {
			method: "put",
			body: data
		})
}
