<template>
	<Modal
		:value="visible"
		:closable="false"
		:mask-closable="false"
		class="start-modal"
	>
		<p slot="header">
			<Icon class="confirm-icon" type="ios-help-circle" />
			<span>启动流程</span>
		</p>
		<div
			style="text-align: center; font-size: 18px; font-weight: 400"
			:style="{ color: listData && listData.length ? '#FF0000' : '' }"
		>
			<span v-if="listData && listData.length"
				><img
					style="width: 30px; height: 30px"
					src="../../../assets/common/exclamatory-mark.png"
					alt="感叹号"
			/></span>
			{{
				listData && listData.length
					? "此流程启动过，您确认再次启动此流程嘛"
					: "您确认启动此流程吗？"
			}}
		</div>
		<div slot="footer">
			<Checkbox v-model="sendSysMessage" :true-value="1" :false-value="0">
				<span class="notify-sty">通知相关人员</span>
			</Checkbox>
			<Button type="text" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk">确定</Button>
		</div>
	</Modal>
</template>
<script>
export default {
	props: ["visible", "listData"],
	data() {
		return {
			sendSysMessage: 1
		}
	},
	methods: {
		onOk() {
			this.$emit("onOk", this.sendSysMessage)
			this.onCancel()
		},
		onCancel() {
			this.$emit("onCancel")
		}
	}
}
</script>
<style lang="less">
.start-modal {
	.ivu-modal-header p {
		height: inherit;
	}

	.confirm-icon {
		color: #f90;
		font-size: 28px;
		margin-right: 12px;
	}

	.notify-sty {
		margin-right: 16px;
		color: #2d8cf0;
	}
}
</style>
